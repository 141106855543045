import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import {is_object} from "@/components/base/utils";

export default {
    name: "BaseSingleTagComponent",
    mixins: [BaseRenderMixin],
    props: {
        tag_data: String
    },
    methods: {
        make_element_tree(createElement, element_data, options = {},) {
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)
            if (style) data.style = style
            if (props) data.props = props
            if (attrs) {
                data.attrs = attrs
            }

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_attrs(element_data) {
            if (element_data.attr) {
                let attrs = {}

                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }
                return attrs
            } else return null
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = JSON.parse(JSON.stringify(element_data.attr.style))

                if (style.backgroundImage) {
                    if (!style.backgroundImage.includes("https://") && !style.backgroundImage.includes("http://")) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }
                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        get_children(element_data, createElement) {
            let children = []
            let deepsel_text_data = null
            let {tag_data} = this.$props

            if (element_data.attr) {
                if ('deepsel-tag' in element_data.attr) deepsel_text_data = tag_data
            }

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {
                        children.push(this.make_element_tree(createElement, item));
                    } else if (item.node === "text") {
                        children.push(deepsel_text_data ? deepsel_text_data : item.text)
                    }
                }
            }
            return children
        },
    }
}
